import AnalyticsInsights from "../components/EventAnalytics/AnalyticsInsights/AnalyticsInsights";
import { AnalyticsETLLogAction, ExecutiveContact, ThunkDispatchType } from "../store";

import { getLocalTimeZone } from "../utils/scheduleUtils";
import { useAnalyticsInsightsState  } from "../store/eventAnalytics/hooks";
import { CabButton, CabModal } from "@CabComponents/index";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CreateExecutiveContactCategoryModal
} from "../components/EventAnalytics/Modals/CreateExecutiveContactCategory/CreateExecutiveContactCategory";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { 
  createAnalyticsExecutiveContactCategory, updateAnalyticsExecutiveContact
} from "../store/eventAnalytics/actions";
import CabinetPage from "../components/Common/CabinetPage";
import { EventLabelAbstractFormInput } from "../components/EventAnalytics/Modals/EventLabelAbstractModal";
import FetchInProgress from "../components/EventAnalytics/FetchInProgress/FetchInProgress";
import EditContactModal from "../components/EventAnalytics/Modals/EditContactModal/EditContactModal";
import colors from "../colors";
import { Box, Typography } from "@mui/material";
import { GridCallbackDetails, GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro";
import CabSpinner from "@CabComponents/CabSpinner";
import { useDebouncedCallback } from 'use-debounce';

const AnalyticsInsightsPage = () => {
  const {
    start,
    end,
    handleChangeDates,
    user,
    handleSetLeader,
    event_types,
    event_categories,
    executive_contacts,
    recurrence_types,
    myLeaders,
    selectedLeader,
    consolidated_locations,
    loading,
    setLoading,
    initialLoading,
    executive_contact_categories,
    analyticsContactResponseData,
    analyticsEventResponseData,
    indexBy,
    keyBy,
    interval,
    handleSetIndexBy,
    handleSetKeyBy,
    handleSetInterval,
    updateExecutiveContact,
    etlStatus,
    maxDate,
    minDate,
    updateContactDataGrid,
    contactGridFilterParameters,
    contact_types
  } = useAnalyticsInsightsState();

  const dispatch = useDispatch<ThunkDispatchType>();

  const [createCategoryId, setCreateCategoryId] = useState<number | null>(null);
  const [openEditContactModal, setOpenEditContactModal] = useState(false);
  const [editContactId, setEditContactId] = useState<number | undefined>();
  const [contactResource, setContactResource] = useState<
  Pick<ExecutiveContact, "id" | "name" | "primary_email" | "is_resource"> | undefined
  >();
  const { control: labelControl, reset: labelReset, getValues: labelGetValues, formState: labelFormState
  } = useForm<EventLabelAbstractFormInput>({
    defaultValues: {
      id: -1,
      name: "",
      color: colors.black200,
      organization: user?.active_license.organization
    }
  });

  const { control: contactControl, setValue, reset: contactReset, getValues: contactGetValues } = useForm<
    Pick<ExecutiveContact, "id" | "name" | "primary_email" | "is_resource">
  >({
    defaultValues: {
      id: -1,
      name: "",
      primary_email: "",
      is_resource: false,
    }
  });

  useEffect(() => {
    const values = contactGetValues();
    if (editContactId && values.id !== editContactId) {
      contactReset({
        id: executive_contacts[editContactId].id,
        name: executive_contacts[editContactId].name,
        primary_email: executive_contacts[editContactId].primary_email
      });
    }
  }, [editContactId, executive_contacts, contactGetValues, contactReset]);

  
  const handleUpdateExecutiveContact = useCallback((data: Partial<ExecutiveContact> & {id: number}) => {
    if (data?.contact_category === -1) {
      data.contact_category = null;
    }
    if (data?.contact_category === -2) {
      setCreateCategoryId(data.id);
    } else {
      updateExecutiveContact(data);
    }
  }, [updateExecutiveContact]);

  const cancelCreateLabel = () => {
    setCreateCategoryId(null);
    labelReset();
  };

  const createLabel = useCallback(() => {
    setLoading(true);
    dispatch(createAnalyticsExecutiveContactCategory (labelGetValues())).then(res => {
      if (res.status === 201) {
        if (createCategoryId) {
          handleUpdateExecutiveContact({ id: createCategoryId, contact_category: res.data.id });
        }
        setCreateCategoryId(null);
        labelReset();
      }
      setLoading(false);
    });
  }, [createCategoryId, dispatch, handleUpdateExecutiveContact, labelGetValues, labelReset, setLoading]);

  const handleEditContact = useCallback((attendeeId: number) => {
    setEditContactId(attendeeId);
    setOpenEditContactModal(true);
  }, []);

  const cancelEditAttendee = useCallback(() => {
    setEditContactId(undefined);
    setOpenEditContactModal(false);
  }, []);

  const updateContact = useCallback(async () => {
    setLoading(true);
    await dispatch(updateAnalyticsExecutiveContact(contactGetValues())).then(res => {
      if (res.status === 200) {
        setOpenEditContactModal(false);
        setEditContactId(undefined);
      }
      setLoading(false);
    });
  }, [contactGetValues, dispatch, setLoading]);

  const convertContactToResource = useCallback(() => {
    setContactResource(contactGetValues());
  }, [contactGetValues]);

  const setContactToResource = useCallback(() => {
    setValue("is_resource", true, {shouldDirty: true});
    updateContact().then( () => {
      setContactResource(undefined);
    });
  }, [setValue, updateContact]);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    const order_by: string[] = [];
    sortModel.forEach((sort) => {
      order_by.push(`${sort["sort"] !== "asc" ? "-" : ""}${sort["field"]}`);
    });
    const updateFilters = {...contactGridFilterParameters};
    updateFilters["order_by"] = order_by;
    updateContactDataGrid(updateFilters);
  }, [contactGridFilterParameters, updateContactDataGrid]);

  const handleFilterModelChange = useDebouncedCallback((model: GridFilterModel, details: GridCallbackDetails) => {
    const order_by = contactGridFilterParameters["order_by"];
    const filters: Record<
      string, string | number | (string | number)[]
    > = {};

    model.items.forEach(item => {
      if (item.value) {
        if (item["operator"] !== "eq") {
          filters[`${item["field"]}__${item["operator"]}`] = item["value"];
        } else {
          filters[item["field"]] = item["value"];
        }
      }
    });

    filters["topology"] = model?.logicOperator || "and";
    if (order_by) {
      filters["order_by"] = order_by;
    }
    updateContactDataGrid(filters);
  }, 1000);

  const onLeaderSelect = useCallback((leaderId: number | null) => 
    handleSetLeader(myLeaders.find(leader => leader.id === leaderId) || null), [handleSetLeader, myLeaders]);

  const contactDataRows = useMemo(() => analyticsContactResponseData?.map(({ attendee: attendeeId, hours }) => {
    const contact = executive_contacts[attendeeId];
    const category = contact?.contact_category || -1;
    const contactType = contact?.contact_type || -1;

    const attendee = {
      id: attendeeId,
      name: contact?.name,
      email: contact?.primary_email,
    };

    return { attendee, hours, category, contactType };
  }) || [], [analyticsContactResponseData, executive_contacts]);

  return (
    <CabinetPage
      pageName={'Insights'}
      headerContent={<>
        <Typography variant="h1">
          Calendar Insights
        </Typography>
      </>}
    >
      {((
        (etlStatus === AnalyticsETLLogAction.STARTED || etlStatus === AnalyticsETLLogAction.QUEUED ||
          etlStatus === AnalyticsETLLogAction.NOT_FETCHED
        ) && (maxDate === null && minDate === null)) ? (
          <FetchInProgress etlStatus={etlStatus} />
        ) : (
          <Box position="relative">
            <AnalyticsInsights
              contactDataRows={contactDataRows}
              start={start}
              end={end}
              handleChangeDates={handleChangeDates}
              tz={getLocalTimeZone()?.name}
              user={user}
              loading={loading}
              initialLoading={initialLoading}
              event_categories={event_categories}
              event_types={event_types}
              consolidated_locations={consolidated_locations}
              executive_contacts={executive_contacts}
              selectPlaceholder={"Select Teammate"}
              execPickerDisabled={false}
              myLeaders={myLeaders}
              analyticsEventResponseData={analyticsEventResponseData}
              analyticsContactResponseData={analyticsContactResponseData}
              indexBy={indexBy}
              handleSetIndexBy={handleSetIndexBy}
              keyBy={keyBy}
              handleSetKeyBy={handleSetKeyBy}
              interval={interval}
              handleSetInterval={handleSetInterval}
              recurrence_types={recurrence_types}
              editContact={handleEditContact}
              executive_contact_categories={executive_contact_categories}
              contact_types={contact_types}
              handleUpdateExecutiveContact={handleUpdateExecutiveContact}
              onLeaderSelect={onLeaderSelect}
              selectedLeader={selectedLeader}
              handleSortModelChange={handleSortModelChange}
              handleFilterModelChange={handleFilterModelChange}
            />
            {loading &&
              <Box 
                sx={{
                  width: "100%",
                  minHeight: "100vh",
                  height: "100%",
                  background: "#fafafa",
                  opacity: 0.7
                }}
                position="absolute"
                display="block"
                top={0}
                left={0}
              >
                <Box
                  position="fixed"
                  left="50%"
                  top="50%"
                  sx={{transform: "translate(-50%, -50%)"}}
                >
                  <CabSpinner scale={4} color='inherit' />
                </Box>
              </Box>
            }
            
          </Box>
        )
      )}
      <CabModal
        open={!!createCategoryId}
        onClose={() => setCreateCategoryId(null)}
        actionButtons={<>
          <CabButton
            buttonType="secondary"
            onClick={() => cancelCreateLabel()}
          >
            Cancel
          </CabButton>
          <CabButton
            onClick={() => createLabel()}
            disabled={!labelFormState.isValid}
          >
            Create Category
          </CabButton>
        </>}
      >
        <CreateExecutiveContactCategoryModal
          control={labelControl}
        />
      </CabModal>
      <CabModal
        open={openEditContactModal}
        title="Edit Contact"
        onClose={() => setOpenEditContactModal(false)}
        actionButtons={<>
          <CabButton
            buttonType="secondary"
            onClick={() => cancelEditAttendee()}
          >
            Cancel
          </CabButton>
          <CabButton
            onClick={() => updateContact()}
          >
            Save Changes
          </CabButton>
        </>}
      >
        <EditContactModal
          control={contactControl}
          convertContactToResource={convertContactToResource}
        />
      </CabModal>
      <CabModal
        open={!!contactResource}
        title="Confirm Contact is Resource"
        onClose={() => setContactResource(undefined)}
        actionButtons={<>
          <CabButton
            buttonType="secondary"
            onClick={() => setContactResource(undefined)}
          >
            Cancel
          </CabButton>
          <CabButton
            onClick={() => setContactToResource()}
          >
            Confirm
          </CabButton>
        </>}
      >
        <Typography>
          Are you sure you want to set
          <Box 
            sx={{ display: "inline", fontWeight: "bold", paddingLeft: 0.5, paddingRight: 0.5}}
          >
            {contactResource?.name || contactResource?.primary_email} 
          </Box> as a resource (e.g. room, conference facility, or other asset)?
        </Typography>
        <Typography sx={{marginTop: 2}}>
          This will hide this contact from your analytics view from now on.
        </Typography>
      </CabModal>
    </CabinetPage>
  );
};

export default AnalyticsInsightsPage;